import Repository from "@/repositories/api.js"
const resource = "/admin/article_collections"

export default {
  async get(collectionID) {
    let response = await Repository.get(`${resource}/${collectionID}`)
    return response.data.article_collection
  },
  async create(collectionData) {
    let response = await Repository.post(`${resource}`, {
      article_collection: collectionData
    })
    return response.data
  },
  async update(collectionID, collectionData) {
    let response = await Repository.patch(`${resource}/${collectionID}`, {
      article_collection: collectionData
    })
    return response.data
  },
  async destroy(collectionID) {
    let response = await Repository.delete(`${resource}/${collectionID}`)
    return response.data
  }
}

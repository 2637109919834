<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <page-content :page-title="collection.parent.name">
      <template #actions>
        <el-button type="primary" icon="fal fa-plus" @click="newArticle">
          Neuer Artikel
        </el-button>
      </template>
      <el-tabs
        v-model="activeTab"
        type="border-card"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="Artikel anzeigen" name="list">
          <el-table v-loading="loading" :data="articles" stripe>
            <el-table-column
              prop="title"
              label="Titel"
              sortable
            ></el-table-column>
            <el-table-column label="Veröffentlicht" sortable>
              <template #default="scope">
                <el-tag v-if="scope.row.published" size="small" type="success">
                  Ja
                </el-tag>
                <el-tag v-else size="small" type="info">Nein</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Geteilt">
              <template #default="scope">
                <template v-if="!scope.row.original"
                  ><el-tag v-if="scope.row.shared" size="small" type="success">
                    Ja
                  </el-tag>
                  <el-tag v-else size="small" type="info"
                    >Nein</el-tag
                  ></template
                >
              </template>
            </el-table-column>
            <el-table-column label="Aktualisiert">
              <template #default="scope">
                <template v-if="!scope.row.original">
                  <el-tag
                    v-if="
                      differenceInCalendarMonths(
                        new Date(),
                        parseISO(scope.row.updated_at)
                      ) < 6
                    "
                    size="small"
                    type="success"
                  >
                    {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
                  </el-tag>
                  <el-tag
                    v-else-if="
                      differenceInCalendarMonths(
                        new Date(),
                        parseISO(scope.row.updated_at)
                      ) < 12
                    "
                    size="small"
                    type="warning"
                  >
                    {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
                  </el-tag>
                  <el-tag v-else size="small" type="danger">
                    {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
                  </el-tag>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Ursprung">
              <template #default="scope">
                <template v-if="scope.row.original">
                  <el-tag
                    v-if="scope.row.original.region_name === 'Musterregion'"
                    size="small"
                    type="info"
                  >
                    Studio Nomai
                  </el-tag>
                  <template v-else>
                    <el-tag size="small" type="info">
                      {{ scope.row.original.region_name }}
                    </el-tag>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Inhalt">
              <template #default="scope">
                <el-popover
                  v-if="
                    scope.row.original_newer && scope.row.differs_from_original
                  "
                  placement="top"
                  trigger="hover"
                >
                  <div>
                    Ursprungsartikel ist neuer als Ihre Version
                  </div>
                  <span slot="reference" @click="goToReview(scope.row.id)">
                    <i
                      class="fa-exclamation-triangle fal"
                      style="color: red;"
                    ></i>
                  </span>
                </el-popover>
                <el-popover
                  v-else-if="
                    !scope.row.original_newer && scope.row.differs_from_original
                  "
                  placement="top"
                  trigger="hover"
                >
                  <div>
                    Ihre Version weicht vom Ursprungsartikel ab
                  </div>
                  <span slot="reference" @click="goToReview(scope.row.id)">
                    <i class="fa-code-branch fal" style="color: orange;"></i>
                  </span>
                </el-popover>
                <el-popover
                  v-else-if="scope.row.original"
                  placement="top"
                  trigger="hover"
                >
                  <div>
                    Beide Versionen stimmen überein
                  </div>
                  <span slot="reference">
                    <i class="fa-check fal" style="color: green;"></i>
                  </span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="Aktionen" width="270">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <el-button
                  v-if="scope.row.differs_from_original"
                  icon="fal fa-code-branch"
                  size="mini"
                  @click="goToReview(scope.row.id)"
                >
                  Vergleichen
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Artikel sortieren" name="sort">
          <el-alert
            title="Hinweis"
            description="Die Artikel können per Drag & Drop sortiert werden."
            type="info"
            show-icon
            style="margin-bottom: 20px;"
          >
          </el-alert>
          <el-tree
            :props="treeProps"
            :data="articles"
            node-key="id"
            draggable
            :allow-drop="allowDrop"
            @node-drop="handleDrop"
          >
          </el-tree>
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
import { format, parseISO, differenceInCalendarMonths } from "date-fns"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import ArticleCollectionsRepository from "@/repositories/article_collections_repository.js"
import ArticlesRepository from "@/repositories/articles_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Artikelsammlung"
  },
  components: {
    TheBreadcrumbs,
    PageContent
  },
  mixins: [ApiErrorHandlerMixin],
  beforeRouteUpdate(to, from, next) {
    if (to.query.mode) {
      this.activeTab = to.query.mode
    } else {
      this.activeTab = "list"
    }
    next()
  },
  data() {
    return {
      format,
      parseISO,
      differenceInCalendarMonths,
      activeTab: "list",
      loading: false,
      collection: {
        parent: {
          name: ""
        }
      },
      articles: [],
      treeProps: {
        label: "title"
      }
    }
  },
  computed: {
    breadcrumbs() {
      let crumbs = [
        {
          path: "/guide",
          text: "Wissenswertes"
        }
      ]
      crumbs = crumbs.concat(this.getItemParents(this.collection).reverse())
      crumbs.pop()
      return crumbs
    }
  },
  async created() {
    this.loading = true

    if (this.$route.query.mode) {
      this.activeTab = this.$route.query.mode
    }

    try {
      this.collection = await ArticleCollectionsRepository.get(
        this.$route.params.id
      )
      this.articles = this.collection.articles
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    handleEdit(article) {
      this.$router.push({
        name: "EditArticlePage",
        query: {
          article_collection_name: this.collection.parent.name,
          article_collection_id: this.collection.id,
          redirect: this.$route.query.redirect || this.$route.fullPath
        },
        params: {
          id: article.id
        }
      })
    },
    newArticle() {
      this.$router.push({
        name: "NewArticlePage",
        query: {
          article_collection_id: this.collection.id,
          article_collection_name: this.collection.parent.name,
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    getItemParents(item) {
      let parents = []
      if (item.parent) {
        parents.push({
          path: `/guide_items/${item.parent.id}`,
          text: item.parent.name
        })
        if (item.parent.parent) {
          parents = parents.concat(this.getItemParents(item.parent))
        }
      }
      return parents
    },
    async handleDrop() {
      const article_ids = this.articles.map(article => article.id)
      ArticlesRepository.sort(article_ids, this.collection.id)
    },
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner"
    },
    handleTabClick(tab) {
      if (tab.$props.name !== this.$route.query.mode) {
        this.$router.push({
          query: { mode: tab.$props.name }
        })
      }
    },
    goToReview(id) {
      this.$router.push({
        name: "ReviewArticleOriginalChangesPage",
        params: { id: id }
      })
    }
  }
}
</script>
